import logo from "./logo.svg";
import "./App.css";
import StarRating from "./components/StarRating";
import { useEffect, useState, useReducer } from "react";

function App() {
  const [name, setName] = useReducer((name) => {
    return name == "day" ? "time" : "day";
  }, "day");
  const [admin, setAdmin] = useState(false);
  useEffect(() => {
    console.log(`Celebrate ${name}`);
  }, [name]);

  useEffect(() => {
    console.log(`The user is : ${name}`);
  });
  return (
    <section>
      <h1>Hello World</h1>
      <p>Enjoy your {name}!</p>
      <button onClick={() => setName("time")}>Click me!</button>
    </section>
  );
}

export default App;
